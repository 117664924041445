export class MemCache {
  static props = {};

  static set(key: string, data: any): void {
    this.props[key] = data;
  }
  static get(key: string): any {
    return this.props[key];
  }
  static exist(key: string): boolean {
    return key in this.props;
  }
  static clear(): void {
    this.props = {};
  }
}