import { IGraphClient, IHttpClient } from '@msx/platform-types';


export class GraphClient implements IGraphClient {
  private readonly httpClient: IHttpClient;
  private readonly graphBaseUrl: string = 'https://graph.microsoft.com/v1.0/';
  private readonly graphResourceUri: string = 'https://graph.microsoft.com';

  public constructor(httpClient: IHttpClient) {
      this.httpClient = httpClient;
  }

  public async getPhoto(upn: string): Promise<string | null> {
      return new Promise(
          async (resolve, reject): Promise<void> => {
              try {
                  const { data } = await this.httpClient.request<Blob>(
                      {
                          url: this.graphBaseUrl + `users/${upn}/photos/96x96/$value`,
                          resource: this.graphResourceUri,
                          responseType: 'blob',
                      },
                      { silentError: true }
                  );

                  const reader = new FileReader();
                  reader.onload = (): void => {
                      if (reader.result) {
                          resolve(reader.result.toString());
                      } else {
                          reject();
                      }
                  };
                  reader.readAsDataURL(data);
              } catch {
                  resolve(null);
              }
          }
      );
  }
}
