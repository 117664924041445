import  { DarkPalette }  from './palette';
export const DarkColors = {
  Application: {
    Primary: '#0078d7',
    Secondary: '#e5e5e5',
    LightBackground: '#ffffff',
    NeutralBackground: '#e5e5e5',
    HighContrastBlackOnWhite: '#FFFFFF !important',
    HighContrastWhiteOnBlack: '#000000 !important',
    HighContrast: '#000000 !important',
  },
  VisualIndicators: {
    GrayIndicator: '#7a7574',
    GreenIndicator: '#498205',
    YellowIndicator: '#fce100',
    RedIndicator: '#d1343b',
    BlueIndicator: '#0078d7'
  },
  Text: {
    Header: DarkPalette.neutralPrimary,
    Primary: DarkPalette.neutralPrimary,
    Secondary: DarkPalette.neutralSecondary,
    HeroAccent: '#0078D7',
    Error: DarkPalette.redDark,
    HighContrastBlackOnWhite: '#000000 !important',
    HighContrastWhiteOnBlack: '#FFFFFF !important',
    HighContrast: '#FFFF00 !important',
    HeadingMain: '#423f3f',
    HeadingSecondary: '#333333',
  },
  Link: {
    PrimaryLink: DarkPalette.themePrimary,
    HeroLink: DarkPalette.themeDark,
    HighContrastBlackOnWhite: '#00009F !important',
    HighContrastWhiteOnBlack: '#FFFF00 !important',
    HighContrast: '#8080FF !important',
  },
  List: {
    HeaderText: DarkPalette.neutralSecondary,
    RowText: DarkPalette.neutralPrimary,
  },
  Item: {
    ListItemHoverBackgroundColor: DarkPalette.neutralLighterAlt,
    ItemIndicator: '#0078d7'
  },
  Icon: {
    SecondaryIcon: DarkPalette.neutralSecondary,
    PrimaryIcon: DarkPalette.neutralPrimary
  }
};
