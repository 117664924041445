import {
  IDxpModalProps, IDxpModalStyles, DxpModalHeight
} from './DxpModal.types';

export const getRootStyle = (props: IDxpModalProps): any => {
  return {
    main: {
      backgroundColor: 'white',
      width: '75vw',
      minHeight: props.height === DxpModalHeight.Max ? '80vh' : '50vh',
      maxHeight: 'auto',
      boxShadow: '0px 7px 17px rgba(0,0,0,.22)',
      overflowY: 'none'
    }
  };
};

export const getStyles = (props: IDxpModalProps): IDxpModalStyles => {
  return {
    headerArea: {
      height: '80px',
      width: '100%',
      display: 'grid',
      color: '#000000',
      gridTemplateColumns: 'auto 40px'
    },
    headerRow: {
      marginTop: '20px'
    },
    titleTextContainer: {
      height: '100%',
      paddingLeft: '32px',
      paddingBottom: '12px',
      display: 'table'
    },
    closeIconContainer: {
      height: '40px',
      width: '40px',
      textAlign: 'center',
      display: 'table',
    },
    titleText: {
      display: 'table-cell',
      verticalAlign: 'bottom',
      color: '#423f3f',
      height: '100%',
      paddingLeft: '32px',
      paddingBottom: '12px',
    },
    closeIcon: {
      display: 'table-cell',
      verticalAlign: 'middle',
      cursor: 'pointer',
    },
    contentArea: {
      padding: '12px 32px 32px 32px',
      minHeight: props.height === DxpModalHeight.Max ? 'calc(75vh - 128px)' : 'calc(50vh - 128px)',
      maxHeight: 'auto'
    },
  };
};
