import {IAppTheme} from '../../../theme/Theme.types'

export const getFabricTableStyles = (theme: IAppTheme): any => {
  //const theme = props.fabricTheme;
  const defaultHeight = "100%";
  return {
    scrollablePaneRoot: {
      height: (props) => (props.height ? props.height : defaultHeight),
    },
  };
};
