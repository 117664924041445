import {IAppTheme} from '../../theme/Theme.types'

export const getTableStyles = (theme:IAppTheme): any => {
  return {
    tableTitle: {
      fontSize: "18px",
      fontStretch: "normal",
      fontFamily: "Segoe UI",
      //color: "#000000",
      minWidth: "400px",
    },
    tableBody: {
      marginTop: "20px",
      height: "100%",
    },
    tableStackItem: {
      position: "relative",
      marginTop: (props) => (props.tableTitle ? "20px" : ""),
      height: "auto",
      // overflow: 'auto', 
      // backgroundColor: 'yellow',
    },
    rootStack: {
      width: "100%",
    },
  };
};
