import {
  IMsxModalProps, IMsxModalStyles, MsxModalHeight
} from './MsxModal.types';

export const getRootStyle = (props: IMsxModalProps): any => {
  return {
    main: {
      //backgroundColor: 'white',
      backgroundColor: props.theme ? props.theme.palette.white : 'white', 
      width: '75vw',
      height: props.height === MsxModalHeight.Max ? '75vh' : '50vh',
      // boxShadow: '0px 7px 17px rgba(0,0,0,.22)',
      overflowX: 'none',
      overflowY: 'none',
      // selectors: {
      //   '@media screen and (max-width: 767px) and (min-width: 641px)': {
      //     height: '65vh',
      //   },
      //   '@media screen and (max-width: 640px) and (min-width: 385px)': {
      //     height: '45vh',
      //   },
      //   '@media screen and (max-width: 384px)': {
      //     width: '40vw',
      //     height: '30vh',
      //   },
      // },
    }
  };
};

export const getStyles = (props: IMsxModalProps): IMsxModalStyles => {
  return {
    headerArea: {
      minHeight: '80px',
      width: '100%',
      display: 'grid',
      //color: '#000000',
      gridTemplateColumns: 'auto 40px'
    },
    headerRow: {
      width: '100%',
      //color: '#000000',
    },
    titleTextContainer: {
      height: '100%',
      paddingLeft: '32px',
      paddingBottom: '12px',
      display: 'table'
    },
    closeIconContainer: {
      height: '40px',
      width: '40px',
      textAlign: 'center',
      display: 'table'
    },
    titleText: {
      display: 'table-cell',
      verticalAlign: 'bottom',
      //color: '#423f3f',
      color: props.theme ? props.theme.palette.black : '#423f3f', 
      height: '100%',
      paddingLeft: '32px',
      paddingBottom: '12px',

    },
    closeIcon: {
      display: 'table-cell',
      verticalAlign: 'middle',
      cursor: 'pointer'
    },
    contentArea: {
      //background: 'white',
      padding: '12px 32px 32px 32px',
      // overflowX: 'auto',
      // overflowY: 'auto',
     //  height: props.height === MsxModalHeight.Max ? 'calc(75vh - 128px)' : 'calc(50vh - 128px)'
      // height: '60vh', // props.height === MsxModalHeight.Max ? 'calc(75vh - 128px)' : 'calc(50vh - 128px)'
      // selectors: {
      //   '@media screen and (max-width: 767px) and (min-width: 641px)': {
      //     height: '100px',
      //   },
      //   '@media screen and (max-width: 640px) and (min-width: 385px)': {
      //     height: '20px',
      //   },
      //   '@media screen and (max-width: 384px)': {
      //     height: '10px',
      //   },
      // },
    }
  };
};
