import moment from 'moment';
const minDate = "01/01/0001";

export const CARD_MIN_HEIGHT = '230px';
export const CARD_MIN_WIDTH = '375px';


export const maskstring = (str: string) => {
  if (!str || str == '--' || str.length == 0) return str;
  let last = str;
  if (str.length > 4)
    last = str.substring(str.length - 4);
  return "*".repeat(6) + last;
}

export class Guid {
  private static s4(): string {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  static newGuid() {
    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }
}

export const formatDate = (str: string) => {
  if (!moment(str).isAfter(minDate))
    return '--';

  return moment(str).format("YYYY-MM-DD");
}

export type sortFnType = (itemA: any, itemB: any) => -1 | 0 | 1;

export function valueOrDefault(value: unknown, defaultValue: string): string {
  if (value === undefined) { return defaultValue; }
  if (typeof value === 'object') {
    if (value === null) { return defaultValue; }
    if (moment.isMoment(value)) { return value.format('L'); }
    return defaultValue;
  }
  if (value === '') { return defaultValue; }
  return String(value);
}


export function SortListItems<T>(
  items: T[],
  sortBy: keyof T,
  descending = false,
  sortFn: sortFnType | undefined = undefined): T[] {
  if (sortFn) {
    items.sort(sortFn);
    if (descending) {
      return items.reverse();
    }
    return items;
  }

  // Exit if we don't have a valid sort column
  if (sortBy === null || sortBy === undefined || sortBy === '') {
    return items;
  }

  if (descending) {
    return items.sort((a: T, b: T) => {
      // Handle sorting when value is null/undefined
      const aVal = valueOrDefault(a[sortBy], '').trim().toLowerCase();
      const bVal = valueOrDefault(b[sortBy], '').trim().toLowerCase();

      if (aVal < bVal) {
        return 1;
      } else if (aVal > bVal) {
        return -1;
      } else {
        return 0;
      }
    });
  } else {
    return items.sort((a: T, b: T) => {
      // Handle sorting when value is null/undefined
      const aVal = valueOrDefault(a[sortBy], '').trim().toLowerCase();
      const bVal = valueOrDefault(b[sortBy], '').trim().toLowerCase();
      if (aVal < bVal) {
        return -1;
      } else if (aVal > bVal) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}

export function isEmpty(objectToCheck) {
  let result = true;
  if (!objectToCheck) return result;
  if (objectToCheck.constructor === Object) {
    result = Object.entries(objectToCheck).length === 0
  }
  if (Array.isArray(objectToCheck)) {
    result = objectToCheck.length === 0;
  }
  return result;
}

export function isEmptyTheme(objectToCheck): boolean {
  let result = isEmpty(objectToCheck);
  if (!result) {
    result = objectToCheck.palette ? false : true;
  }
  return result;
}

