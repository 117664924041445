import { ICardProps } from './MsxCard.types';
import { IMsxCardStyles, IMsxCardProps } from './MsxCard.types';
import { CARD_MIN_HEIGHT, CARD_MIN_WIDTH } from '../../utils/helpers';

export type MsxCardProps = ICardProps & IMsxCardProps;

export const getStyles = (props: MsxCardProps): IMsxCardStyles => {
  const { noDataSettings, cardFrameContent } = props;
  const minHeight = cardFrameContent ? cardFrameContent.minHeight : CARD_MIN_HEIGHT;
  const minWidth = cardFrameContent ? cardFrameContent.minWidth : CARD_MIN_WIDTH;
  const theme = props.theme;
  return {
    root: {
      //display: 'flex', // commenting to support old extensions. remove later to have proper responsive design
      backgroundColor: theme ? theme.palette.white : 'transparent',
      height: minHeight, // '100%',
      width:  minWidth, // '100%',
      border: '1px solid rgba(204,204,204,.5)',
      boxShadow: '0 1.6px 3.6px 0 rgba(0,0,0,.132), 0 0.3px 0.9px 0 rgba(0,0,0,.108)',
      // minHeight,
      // minWidth,
    },
    layoutRoot: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '8px',
      //backgroundColor: 'red',
    },
    contentLayout: {
       display: 'flex',
       flex: 1,
       flexDirection: 'row'
    },
    contentAreaLayout: {
      display: 'flex',
      width: '100%',
      overflow: 'hidden',
      flex: 1,
      //backgroundColor: 'green',
    },
    contentArea1: {
      flex: 1,
      overflow: 'auto', // 'hidden'
      //backgroundColor: 'yellow',
    },
    loading: {
      width: '100%',
      minWidth: '392px',
      height: '100%',
      display: 'grid',
      alignContent: 'center',
      justifyContent: 'center'
    },
    noDataContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      //color: DefaultColors.Text.Secondary,
      cursor: noDataSettings && noDataSettings.noDataAction ? 'pointer' : 'default'
    },
    noDataIcon: {
      fontSize: '40px',
      height: '40px',
      width: '40px',
      //color: DefaultColors.Icon.SecondaryIcon,
      paddingBottom: '8px'
    }
  };
};
