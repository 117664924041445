import { ICardFrameStyles, ICardFrameProps } from './MsxCardFrame.types';
import { CARD_MIN_HEIGHT, CARD_MIN_WIDTH } from '../../../utils/helpers';

const cardTitleBox = 40;

export const getStyles = (props: ICardFrameProps): ICardFrameStyles => {
  const { fontFamily, fontSize, seperatorColor, disableDrag } = props;

  return {
    root: {
      // height: CARD_MIN_HEIGHT,
      // width: CARD_MIN_WIDTH,
      height: '100%',
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      //resize: 'both'
    },
    cardTitleBox: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: cardTitleBox,
      borderBottom: '0px solid',
      borderBottomColor: seperatorColor ? seperatorColor : 'rgba(0,0,0,0.1)',
      borderTopLeftRadius: '2px',
      borderTopRightRadius: '2px',
      cursor: disableDrag ? '' : 'move',
      transition: 'background-color .2s,color .2s,margin .2s,padding .2s,border-color .2s',
      selectors: {
        ':hover': {
          backgroundColor: disableDrag ? '' : ''
        }
      }
    },
    cardTitleEllipsisButton: {
      width: 40,
      height: 40
    },
    cardTitleActionButton: {
      height: 40
    },
    cardTitle: {
      overflow: 'hidden',
      flex: 1,
      lineHeight: '30px',
      paddingLeft: '16px',
      fontSize: fontSize ? fontSize : '18px',
      fontFamily: fontFamily ? fontFamily : 'Segoe UI',
      fontWeight: 600,
      display: 'inline-block'
    },
    ellipsisIcon: {
      paddingTop: 12
    },
    layout: {
      display: 'flex',
      flex: 1,
      padding: '0 16px 16px 16px'
    },
    ellipsisButtonStyle: {
      width: '100%',
      height: '100%'
    },
    expandLinkButtonStyle: {
      marginRight: '10px',
    },
  };
};
