import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loadingMessage: {
    id: 'loadingMessage',
    defaultMessage: 'Loading...',
  },
  extensionErrorDetailHeading: {
    id: 'extensionErrorDetailHeading',
    defaultMessage: 'Error',
  },
  extensionErrorMessageTitle: {
    id: 'extensionErrorMessageTitle',
    defaultMessage: 'Sorry, we ran into a problem',
  },
  extensionErrorMessageSubTitle: {
    id: 'extensionErrorMessageSubTitle',
    defaultMessage: 'Tell us about this problem to help us fix it.',
  },
  extensionErrorMessageDetail: {
    id: 'extensionErrorMessageDetail',
    defaultMessage: 'View error details',
  },
  extensionErrorMessageRefresh: {
    id: 'extensionErrorMessageRefresh',
    defaultMessage: 'Refresh',
  },
  extensionErrorMessageFeedback: {
    id: 'extensionErrorMessageFeedback',
    defaultMessage: 'Give feedback to us',
  },
  extensionErrorDetailName: {
    id: 'extensionErrorDetailName',
    defaultMessage: 'Extension',
  },
  extensionErrorDetailErrorLine: {
    id: 'extensionErrorDetailErrorLine',
    defaultMessage: 'Line',
  },
  extensionErrorDetailErrorColumn: {
    id: 'extensionErrorDetailErrorColumn',
    defaultMessage: 'Column',
  },
  extensionErrorDetailComponent: {
    id: 'extensionErrorDetailComponent',
    defaultMessage: 'Component',
  },
  extensionErrorDetailErrorReason: {
    id: 'extensionErrorDetailErrorReason',
    defaultMessage: 'Error reason',
  },
  extensionErrorDetailErrorStack: {
    id: 'extensionErrorDetailErrorStack',
    defaultMessage: 'Details',
  },
});

export default messages;
