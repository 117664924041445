export interface IDetailsListStyles {
  scrollablePaneRoot: any;
}

export const getStyles = (props: any): IDetailsListStyles => {
  const defaultHeight = '300px';
  const newHeight = props && props.height ? props.height : defaultHeight;
  return {
    scrollablePaneRoot: {
      height: newHeight,
      position: 'relative',
    }
  }
};
