import * as React from 'react';
import { Provider } from 'react-redux';
import { IStoreBuilderResult } from '../models/IStoreBuilderResult';
import { ReduxContext } from '../context/ReduxContext';
import { useSelector, ReactReduxContext } from 'react-redux';
import { ShellComponentType } from '../../Components/Shell/Shell.types';

export function withStore<T>(
    storeBuilderResult: IStoreBuilderResult<T>
): (WrappedComponent: ShellComponentType) => React.ComponentType {
    const { store, context, ...otherResults } = storeBuilderResult;

    return (WrappedComponent: ShellComponentType): React.ComponentType => {
        const ComponentWithStore: React.ComponentType = (props: React.PropsWithChildren<{}>): React.ReactElement => {
            const { children } = props;

            return (
                <Provider store={store}>
                    <ReduxContext.Provider
                        value={{
                            dispatch: store.dispatch,
                            useSelector,
                            // eslint-disable-next-line @typescript-eslint/camelcase
                            __redux_context__: ReactReduxContext,
                            ...otherResults,
                        }}
                    >
                        <WrappedComponent {...context}>{children}</WrappedComponent>
                    </ReduxContext.Provider>
                </Provider>
            );
        };

        return ComponentWithStore;
    };
}
