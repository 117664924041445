import {IAppTheme} from '../../../theme/Theme.types'

export const getTableFilterDropdownCollectionStyles = (theme:IAppTheme): any => {
  return {
    collectionStack: {
      width: "100%",
    },
    collectionActionButtonStack: {
      width: "100%",
      paddingTop: "20px",
    },
    collectionActionButton: {
      marginTop: "auto",
    },
    addRemoveIconButton: {
      height: "28px",
      top: "30px",
    },
  };
};
