import * as React from 'react';
import { IRoleView, ILeftNavbarProps } from './MsxNavBar.types';
import { NavToggler, NavGroupType, ICustomNavLinkGroup, INavLinks } from './NavToggle';
import './MsxLeftNavBar-local.css';

const navbarSection = {
  topArea: 'topArea',
  bottomArea: 'bottomArea'
};

class MsxLeftNavbar extends React.Component<ILeftNavbarProps> {

  constructor(props: ILeftNavbarProps) {
    super(props);
  }

  render() {
    return (
      <div className={['leftNavbarNavToggle'].join(' ')}>
        <NavToggler
          groups={this.getNavLinks()}
          dataHint="LeftNav"
          enableCustomization={true}
          selectedKey={this.deriveSelectedKeyFromUrl()}
          isNavCollapsed={true}
        />
      </div>
    );
  }

  private deriveSelectedKeyFromUrl = (): string => {
    let viewName = ''; 
    let roleViewToSelect: IRoleView = this.props.layoutConfig
      .views.filter((view: IRoleView) => view.area === navbarSection.topArea)
      .find(((v: IRoleView) => v.url === viewName));

    if (roleViewToSelect) {
      return roleViewToSelect.key;
    } else if (viewName === '') {
      return '1';
    } else {
      return '0';
    }
  }

  private getNavLinks() {
    const navLinkGroups: ICustomNavLinkGroup[] = [];
    navLinkGroups.push(this.getHamburgerMenu());
    navLinkGroups.push(this.getLinkGroup());
    return navLinkGroups;
  }

  private getHamburgerMenu() {
    let hamburgerLink: ICustomNavLinkGroup = {
      links: [{
        name: 'Left navigation',
        alternateText: 'Left navigation',
        url: '#',
        icon: 'GlobalNavButton',
        key: 'LeftNavHamburger',
        role: 'button',
      }],
      groupType: NavGroupType.ToggleGroup
    };

    return hamburgerLink;
  }

  private getLinkGroup() {
    let navLinks: INavLinks[] = [];

    let group: ICustomNavLinkGroup = {
      name: 'main link group',
      links: navLinks,
      groupType: NavGroupType.MenuGroup
    };

    // filter view items bases on toggle value
    let viewLinks: IRoleView[] = this.props.layoutConfig
      .views.filter((view: IRoleView) => view.area === navbarSection.topArea);

    viewLinks.forEach((v: IRoleView, index: number) => {
      navLinks.push(
        {
          name: v.viewName,
          url: v.url,
          target: '_blank',
          key: v.key,
          icon: v.icon,
          isHidden: v.isHidden,
          onClick:
            (e: React.MouseEvent<HTMLElement>, item: INavLinks) => {
              if (e) {
                e.nativeEvent.preventDefault();
              }
              this.props.navigationCallbackToParent(item.name, item.url);
            },
          'aria-posinset': v['aria-posinset'],
          'aria-setsize': v['aria-setsize']
        }
      );
    });
    return group;
  }
}

export default MsxLeftNavbar;
