import { ICardProps } from './Card.types';
import { IDxpCardStyles } from './DxpCard.types';

export const getStyles = (props: ICardProps): IDxpCardStyles => {
  return {
    root: {
      backgroundColor: 'transparent',
      height: '100%',
      width: '100%'
    },
    layoutRoot: {
      width: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '8px'
    },
    contentLayout: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row'
    },
    contentAreaLayout: {
      display: 'flex',
      overflow: 'hidden',
      flex: 1
    },
    contentArea1: {
      flex: 1,
      overflow: 'hidden'
    },
    loading: {
      width: '100%',
      height: '100%',
      display: 'grid',
      alignContent: 'center',
      justifyContent: 'center'
    },
    noDataContent: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    },
    noDataIcon: {
      fontSize: '50px',
      height: '50px',
      width: '50px'
    }

  };
};
