import {IAppTheme} from '../../../theme/Theme.types'

export const getTableFilterDropdownStyles = (theme:IAppTheme): any => {
  return {
    containerStack: {
      witdh: "100%",
    },
    dropdown: {
      width: "150px",
    },
  };
};
