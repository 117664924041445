import { IDashboardTile } from "../..";
import { InjectedIntl } from 'react-intl';
import messages from './MsxDashboard.message';

export type ComponentStateProps = {
  [key: string]: any
};

export interface DashboardProps {
  tiles: JSX.Element[];
  onRenderBody: (tiles:JSX.Element[], state:ComponentStateProps) => JSX.Element; 
  onBeginChanges: () => void;
  onCancelChanges: () => void;
  onSaveChanges: (userDashboardTiles: IDashboardTile[]) => void;
  messages?: IDashboardMessage;
}

export interface IDashboardMessage {
  dataLoadingText: string;
  dashboardHeadingText: string;
  revertToDefaultButtonText: string;
  addNewCardButtonText: string;
  cancelEditButtonText: string;
  editDashboardButtonText: string;
  doneEditingButtonText: string;
  addnewCardHeadingText: string;
  addNewCardFooterAddButtonText: string;
  addNewCardFooterCancelButtonText: string;
}

export const getDefaultDashboardMessages = (): IDashboardMessage => {
  return {
    dataLoadingText: messages.dataLoadingText.defaultMessage,
    dashboardHeadingText: messages.dashboardHeadingText.defaultMessage,
    revertToDefaultButtonText: messages.revertToDefaultButtonText.defaultMessage,
    addNewCardButtonText: messages.addNewCardButtonText.defaultMessage,
    cancelEditButtonText: messages.cancelEditButtonText.defaultMessage,
    editDashboardButtonText: messages.editDashboardButtonText.defaultMessage,
    doneEditingButtonText: messages.doneEditingButtonText.defaultMessage,
    addnewCardHeadingText: messages.addnewCardHeadingText.defaultMessage,
    addNewCardFooterAddButtonText: messages.addNewCardFooterAddButtonText.defaultMessage,
    addNewCardFooterCancelButtonText: messages.addNewCardFooterCancelButtonText.defaultMessage,

  }
}

